<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex align-self-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Loans & Salary advance applications</span>
          <v-spacer></v-spacer>

          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      class="rounded-xl"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu11 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      hide-details
                      dense
                      class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="status"
                  :items="statusList"
                  outlined 
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Status"
                  clearable
                >
                </v-select>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-autocomplete
                      v-model="employeeId"
                      :items="empList"
                      item-text="name"
                      :search-input.sync="search1"
                      item-value="_id"
                      clearable
                      outlined
                      hide-details
                      label="Employee"
                      class="rounded-xl"
                      dense
                    ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center v-if="loanList.length > 0">
          <v-flex xs12 align-self-center>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Description</th> 
                    <th class="text-left">Status</th> 
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in loanList"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.create_date"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.create_date.slice(0, 10) }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.employeeId"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.employeeId.name }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.amount"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.amount }}</span
                      >
                    </td>

                    <td>
                      <span
                        v-if="item.type"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.type }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.description"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.description.slice(0,56) }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.status"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.status }}</span
                      >
                    </td>
                    
                   
                    <td>
                      <v-layout wrap>
                        <v-flex xs6 sm4 md2 pa-2>
                          <v-btn
                            color="#005f32"
                            style="font-family: kumbhBold"
                            small
                            dark
                            fab
                             plain
                            depressed
                            :to="'ViewLoan?id='+ item._id"
                          >
                          <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Items found!</span>
          </v-flex>
        </v-layout>

        <v-dialog persistent v-model="editdialog" max-width="70%">
          <v-card tile>
            <v-layout wrap v-if="editingitem">
              <v-flex
                xs12
                pa-4
                text-left
                align-self-center
                style="background: #005f32 !important"
              >
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Ressign Asset</span
                >
              </v-flex>
              <v-container>
                <v-layout wrap pa-4>
                  <v-flex
                    xs12
                    sm6
                    pa-2
                    align-self-center
                    text-left
                    v-if="editingitem.assetId"
                  >
                    <v-text-field
                      v-model="editingitem.assetId.name"
                      label="Asset"
                      outlined
                      dense
                      readonly
                      hide-details
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 pa-2 align-self-center text-left>
                    <v-select
                      v-model="assignType"
                      :items="assignList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose assign type"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="
                      assignType == 'Department' || assignType == 'Employee'
                    "
                  >
                    <v-select
                      v-model="departmentId"
                      :items="deptList"
                      item-text="deptName"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      @change="employeeId = null"
                      label="Choose department"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="assignType == 'Employee'"
                  >
                    <v-autocomplete
                      v-model="employeeId"
                      :items="empList"
                      item-text="name"
                      :search-input.sync="search1"
                      item-value="_id"
                      :rules="[rules.required]"
                      required
                      outlined
                      hide-details
                      label="Choose employee"
                      dense
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="quantity"
                      label="Quantity"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                tile
                outlined
                @click="(editdialog = false), getData()"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                v-if="!g"
                color="#005f32"
                dark
                tile
                @click="validateEdit(editingitem._id)"
              >
                Reassign
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      loanList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      assetId: null,
      assetList: [],
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false, 
      status: null,

      statusList: ['Pending', 'Approved', 'Rejected'],
      employeeId: null,
      departmentId: null,
      search1: null,

      empList: [],
      deptList: [],
      quantity: null,
    };
  },
  components: {
    // ImageComp,
  },
  watch: {
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.getData();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    status() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },  
    search1() {
      this.getEmp();
    },
    employeeId() {
      this.currentPage = 1;
      this.getData()
    }
  },
  mounted() { 
    this.getEmp();
    if (this.fromDate) {
      this.fromDate = null;
    }
    if (this.toDate) {
      this.toDate = null;
    }
    this.getData(); 
  },

  methods: {
    

    getEmp() {
      
      this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: { 
          keyword: this.search1, 
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/salaryAdvance_Loans/list",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          status: this.status, 
          employeeId: this.employeeId,

          page: this.currentPage,
          limit: 20,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.loanList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.getData();
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editcat(item) {
      this.editingitem = item;

      this.getEmp();
      // if (item.departmentId) this.departmentId = item.departmentId._id;
      // if (item.employeeId) this.employeeId = item.employeeId._id;
      this.editdialog = true;
    },
    validateEdit() {
      if (this.quantity > this.editingitem.quantity) {
        this.msg =
          "Quantity should be less than or same as already assigned quantity!";
        this.showsnackbar = true;
        return;
      } else if (!this.assignType) {
        this.msg = "Please provide assign type";
        this.showsnackbar = true;
        return;
      } else if (!this.departmentId) {
        this.msg = "Please choose a department";
        this.showsnackbar = true;
        return;
      } else if (this.assignType == "Employee" && !this.employeeId) {
        this.msg = "Please choose an employee";
        this.showsnackbar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      var data = {};
      data["inventoryStockId"] = this.editingitem._id;
      data["assignType"] = this.assignType;
      data["departmentId"] = this.departmentId;
      data["employeeId"] = this.employeeId;
      data["quantity"] = this.quantity;
      axios({
        url: "/asset/reassign/transfer",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    clearOff() {
      this.employeeId = null;
      this.assignType = null;
      this.quantity = null;
      this.departmentId = null;
    },
  },
};
</script>